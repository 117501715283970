import * as React from "react"
import MinistryLogo from "../../images/logo/dotace-voucher.png"
import "./ministry.scss"

const MinistryVoucher = () => {
    return (
          <div className="voucher-div">
              <a
              href="https://www.mkcr.cz/kreativni-vouchery-cs-2634"
              target="_blank" 
              rel="noopener noreferrer">
                  <img src={MinistryLogo} alt="ministry" className="voucher-img"/>
              </a>
          </div>
    )
  }
  
  export default MinistryVoucher