/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { injectIntl } from "gatsby-plugin-intl"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

const Layout = ({ children, intl }) => {
  const [generateLeady, setGenerateLeady] = useState(false);

  return (
    <div className="content-container">
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="a108b8cf-7e99-4e2f-9b63-cad663302445" data-blockingmode="auto" type="text/javascript"></script>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/css/flag-icon.min.css" rel="stylesheet" />
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
        <meta name="facebook-domain-verification" content="732lwr8bt2uw7h8xdx2kygy1hhb39u" />
        {/* Sklik
        <script type="text/javascript" src="https://c.seznam.cz/js/rc.js"></script>
        <script>{`var retargetingConf = { rtgId: 1421955 }; if (window.rc && window.rc.retargetingHit) { window.rc.retargetingHit(retargetingConf); }`}</script>

        <script type="text/javascript" src="https://c.seznam.cz/js/rc.js"></script>
        <script>{`var conversionConf = { id: 100201938, value: null }; if (window.rc && window.rc.conversionHit) { window.rc.conversionHit(conversionConf); }`}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11335572318"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11335572318');
          `
          }
        </script> */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-NL7P37BR');`}
        </script>

        {/* <!-- Meta Pixel Code --> */}
        <script>
          {
            `!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '1618450995648656');
              fbq('track', 'PageView');`
          }
        </script>
        <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1618450995648656&ev=PageView&noscript=1" />`}</noscript>
        {/* <!-- End Meta Pixel Code --> */}
      </Helmet>
      <Header siteTitle={intl.formatMessage({ id: "title" })} intl={intl} />
      <div className="main">
        {children}
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)