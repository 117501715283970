import * as React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MinistryVoucher from "../components/Ministry/ministry"

const Ministry = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "title" })}
        keywords={['helios, helios inuvio, helios erp, erp, helios orange inuvio, helios brno, erp systém, solutions, helios orange, asseco solutions, asseco']}
      />
      <MinistryVoucher/>
    </Layout>
  )
}

export default Ministry